const titles = Array.from(document.querySelectorAll('header.blog-article-header'));

var toggleHeader = (header) => {
  window.pageYOffset > 30 ? header.classList.remove('hidden') : header.classList.add('hidden');
}

var changeTitle = (header) => {
  const negativeTitles = Array.from(document.querySelectorAll('header.blog-article-header.negative'));

  titles.map(title => {
    title.getBoundingClientRect().top < 60 ? title.classList.add('negative') : title.classList.remove('negative');
  })
  
  var lastTitle = negativeTitles[negativeTitles.length - 1];
  header.querySelector('#blog-header-title').innerHTML = lastTitle.querySelector('h2.blog-article-title').innerHTML;
  header.querySelector('#blog-header-date').innerHTML = lastTitle.querySelector('h2.blog-article-date').innerHTML;
}

export { toggleHeader, changeTitle };
