import * as home from './modules/js/home';
import * as menu from './modules/js/menu';
import * as blog from './modules/js/blog';
import * as grid from './modules/js/art';
import * as projects from './modules/js/projects';

var pgurl = window.location.href.substr(window.location.href.lastIndexOf("/"));

function animate(elem,style,unit,from,to,time,prop){if(!elem){return}
var start=new Date().getTime(),timer=setInterval(function(){var step=Math.min(1,(new Date().getTime()-start)/time);if(prop){elem[style]=(from+step*(to-from))+unit}else{elem.style[style]=(from+step*(to-from))+unit}
if(step===1){clearInterval(timer)}},25);if(prop){elem[style]=from+unit}else{elem.style[style]=from+unit}}



////// MENU

const menuDiv = document.getElementById('left-menu');
var topMenu = document.querySelector('ul.xo0 li.title-menu');

if (window.matchMedia("(min-width: 768px)").matches) {
  topMenu.addEventListener('click', function(e){ e.preventDefault(); const toggleMenu = menu.clickOpening(menuDiv); })
  menuDiv.addEventListener('mouseenter', function(e){ const toggleMenu = menu.opening(menuDiv, true); })
  menuDiv.addEventListener('mouseleave', function(e){ const toggleMenu = menu.opening(menuDiv, false); })
}



document.getElementById('mobile-nav').addEventListener('click', function(e){ const toggleClose = menu.closeDiv(document.getElementById('icon-close'), menuDiv); })

if (pgurl.indexOf('/art') == 0 || pgurl.indexOf('/graphic') == 0 ){
  var hash = document.location.hash;
  console.log(hash);
  if (hash == '#list') {
    menu.format('line');
  } else {
    menu.format('grid');
  }
  document.querySelector('li#line-temp').addEventListener('click', function(){
    if (!document.querySelector('li#grid-temp').classList.contains('inactive')){
      menu.format('line');
    }
  })
  document.querySelector('li#grid-temp').addEventListener('click', function(){
    if (!document.querySelector('li#line-temp').classList.contains('inactive')){
      menu.format('grid');
    }
  })
}


////// HEADER BLOG

if (pgurl.indexOf('/blog') == 0){
  const headerBlog = document.getElementById('blog-header');
  const arrowBlog = document.getElementById('arrow-temp');
  window.addEventListener('scroll', function(){
    const toggleHeader = blog.toggleHeader(headerBlog);
    const changeTitle = blog.changeTitle(headerBlog);
  })
  arrowBlog.addEventListener('click', function(){
    animate(document.documentElement, "scrollTop", "", document.documentElement.scrollTop, 0, 200, true);
  })
}



////// FILTER GRID

if (pgurl.indexOf('/art') == 0 || pgurl.indexOf('/graphic') == 0){
  const filterItems = document.querySelectorAll('.filter-list-item');
  const imgGrid = Array.from(document.querySelectorAll('div.outerdiv-grid'));
  document.querySelector('div#all').addEventListener('click', function(){
    const showAll = grid.showAll();
  });
  filterItems.forEach((filterItem) => {
    filterItem.addEventListener('change', function(){
      const clickFilter = grid.filter(filterItem);
    });
  });
  for (let i = 0; i < imgGrid.length; i++) {
    imgGrid[i].addEventListener('mouseenter', function(e){ grid.hoverLine(this, true); })
    imgGrid[i].addEventListener('mouseleave', function(e){ grid.hoverLine(this, false); })
  }
}


////// SLIDESHOW HOME

if (pgurl == '/' || pgurl == '/en'){
  const slideshows = document.querySelectorAll('article#slideshow-home > div');
  const initSlideshow = home.init();
  var slideshowInterval = setInterval(() => { home.slideChange(); }, 3000);

  slideshows.forEach((slideshow) => {
    slideshow.addEventListener('click', function(){
      const clickSlideshow = home.slideChange(slideshow);
      clearInterval(slideshowInterval);
      slideshowInterval = setInterval(() => { home.slideChange(); }, 3000);
    });
  })
}



////// SLIDESHOW PROJECTS

if (pgurl.indexOf('/projets') == 0){
  var hash = document.location.hash;

  const initProjectSlideshow = projects.init();
  const slideNav = document.querySelectorAll('span.slideNav');
  const imagesSlideshow = document.querySelectorAll('#left-slideshow img');
  const zoomDiv = document.getElementById('zoom-projects');

  if (hash) projects.goHash(hash);

  slideNav.forEach((nav) => {
    nav.addEventListener('click', function(){
      const clickProjectSlideshow = projects.slideChange(nav);
    });
  })

  imagesSlideshow.forEach((img) => {
    img.addEventListener('click', function(){
      zoomDiv.innerHTML = "<img src='" + img.dataset.src + "' />";
      zoomDiv.classList.toggle('active');
    });
  })

  zoomDiv.addEventListener('click', function(){
    setTimeout(function(){
      zoomDiv.innerHTML = "";
    }, 300)
    zoomDiv.classList.toggle('active');
  });

  document.addEventListener('mousemove', (e) => {
    projects.zoom(e, zoomDiv);
  })
}







//////////////////
// TARGET BLANK //
//////////////////

document.addEventListener('DOMContentLoaded', function(){
  var internal = location.host.replace("www.", "");
      internal = new RegExp(internal, "i");
  var a = document.getElementsByTagName('a');
  for (var i = 0; i < a.length; i++) {
    var href = a[i].host;
    if( !internal.test(href) ) {
      a[i].setAttribute('target', '_blank');
    }
  }
});
