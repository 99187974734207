const imgGrid = Array.from(document.querySelectorAll('div.outerdiv-grid'));

var filter = (item) => {
  let checkedInput = Array.from(document.querySelectorAll('input[type="checkbox"]:checked'));
  let arrSelected = [];
  let arrTypes = [];

  if (checkedInput.length > 0){
    checkedInput.map(input => {
      if (arrSelected[input.name] == undefined) arrSelected[input.name] = [];
      arrSelected[input.name].push(input.value);
      if (arrTypes.indexOf(input.name) < 0) arrTypes.push(input.name);
    })

    imgGrid.map(img => {
      img.classList.add('hidden');
      let intKeyCount = 0;
      for (let i in arrTypes) {
        let blnFound = false;
        for (let val in arrSelected[arrTypes[i]]) {
          if (img.getAttribute('data-' + arrTypes[i]).indexOf(arrSelected[arrTypes[i]][val]) > -1) blnFound = true;
        }
        if (blnFound) intKeyCount++;
      }
      if (intKeyCount > 0 && intKeyCount != arrTypes.length - 1) img.classList.remove('hidden');
    })

  } else {
    imgGrid.map(i => i.classList.remove('hidden'));
  }
}

var showAll = () => {
  let checkedInput = Array.from(document.querySelectorAll('input[type="checkbox"]:checked'));
  imgGrid.map(i => i.classList.remove('hidden'));
  checkedInput.map(i => i.checked = false);
}

var hoverLine = (line, bool) => {
  let imgDiv = document.getElementById('img-hover');
  if (document.getElementById('art-grid') != undefined && document.getElementById('art-grid').classList.contains('line-template') || document.getElementById('graphic-grid') != undefined && document.getElementById('graphic-grid').classList.contains('line-template')){
    if (bool){
      imgDiv.innerHTML = '<img src="' + line.children[0].children[0].src + '"></img>';
      let text = line.children[0].children[1].getBoundingClientRect();
      let hei = document.querySelector('div.content').getBoundingClientRect().height > window.innerHeight ? document.querySelector('div.content').getBoundingClientRect().height : window.innerHeight;
      let imgHeight = window.innerHeight * 0.4;
      console.log(text, imgDiv.getBoundingClientRect(), line.getBoundingClientRect());
      if (line.getBoundingClientRect().top + window.pageYOffset + imgHeight + 80 < hei){
        var top = text.width + imgDiv.getBoundingClientRect().width + 100 > line.getBoundingClientRect().width ? text.bottom * 1 + 10 : text.top + window.innerHeight * 0.01;
      } else {
        var top = text.width + imgDiv.getBoundingClientRect().width + 100 > line.getBoundingClientRect().width ? text.top - imgHeight - window.innerHeight * 0.02 : text.bottom - imgHeight - window.innerHeight * 0.015;
      }
      let left = text.width + imgDiv.getBoundingClientRect().width + 100 > line.getBoundingClientRect().width ? text.right * 1 - imgDiv.getBoundingClientRect().width - 60 : text.right - 30;
      let scroll = window.pageYOffset;
      let topCorrected = top + scroll;
      imgDiv.style.top = topCorrected + 'px';
      imgDiv.style.left = left + 'px';
    } else {
      imgDiv.innerHTML = '';
    }
  }
}


export { filter, showAll, hoverLine };
