var clickOpening = (menu) => {
  menu.classList.toggle('big');
  menu.classList.toggle('small');
}

var opening = (menu, bool) => {
  if (bool){
    menu.classList.add('big');
    menu.classList.remove('small');
  } else {
    menu.classList.remove('big');
    menu.classList.add('small');
  }
}

var format = (item) => {
  let it = document.getElementById(item + '-temp');
  let sibling = it.nextElementSibling == null ? it.previousElementSibling : it.nextElementSibling;
  let grid = document.getElementById('art-grid') || document.getElementById('graphic-grid');
  it.classList.remove('inactive');
  sibling.classList.add('inactive');
  grid.classList.remove(sibling.id + 'late');
  grid.classList.add(it.id + 'late');
}

var closeDiv = (closeButton, menu) => {
  closeButton.classList.toggle('active');
  menu.classList.toggle('big');
  menu.classList.toggle('small');
}

export { clickOpening, opening, format, closeDiv };
