const slideshows = Array.from(document.querySelectorAll('article#slideshow-home > div[id*="slideshow"]'), slideshow => slideshow.querySelectorAll('.outerslide'));
const slideCount = document.getElementById('slidehome-count');

var init = () => {
  slideshows.map(i => i[0].classList.add('activeSlide'));
  indexSlide();
}

var indexSlide = () => {
  slideshows.map(slideshow => {
    slideshow.forEach((slide, i, array) => {
      slide.dataset.index = i + 1;
      slide.dataset.total = array.length;
      if (i > 0) slide.classList.add('nextSlide');
    })
  })
}

var slideChange = (el) => {
  let activeSlide = Array.from(document.querySelectorAll('.activeSlide'));
  activeSlide.map(active => {
    console.log(active.dataset.index, active.dataset.total);
    if (el != undefined && el.id == "slideshow-left"){
      active.dataset.index > 1 ? prevSlide(active, active.previousElementSibling) : lastSlide(active);
    } else {
      active.dataset.index * 1 < active.dataset.total * 1 ? nextSlide(active, active.nextElementSibling) : firstSlide(active);
    }
  })
  updateCount();
}

var prevSlide = (el, prev) =>{
  el.classList.remove('activeSlide');
  el.classList.add('nextSlide');
  prev.classList.remove('prevSlide');
  prev.classList.add('activeSlide');
}

var nextSlide = (el, next) => {
  el.classList.remove('activeSlide');
  el.classList.add('prevSlide');
  next.classList.remove('nextSlide');
  next.classList.add('activeSlide');
}

var lastSlide = (el) => {
  el.classList.remove('activeSlide');
  slideshows.map(slideshow => {
    slideshow[slideshow.length - 1].classList.add('activeSlide');
    slideshow.forEach((slide, i, array) => {
      slide.classList.remove('nextSlide');
      if (i < array.length - 1) slide.classList.add('prevSlide');
    })
  })
}

var firstSlide = (el) => {
  el.classList.remove('activeSlide');
  slideshows.map(slideshow => {
    slideshow[0].classList.add('activeSlide');
    slideshow.forEach((slide, i, array) => {
      slide.classList.remove('prevSlide');
      if (i > 0) slide.classList.add('nextSlide');
    })
  })
}

var updateCount = () => {
  let activeSlide = document.querySelector('.activeSlide');
  slideCount.innerHTML = '<span>' + activeSlide.dataset.index + '</span><span>' + activeSlide.dataset.total + '</span>';
}

export { init, slideChange };
