const projectSlideshows = Array.from(document.querySelectorAll('.projectSlideshow'), slideshow => slideshow.querySelectorAll('.outerslide'));
const projectSlideCount = document.getElementById('slideNext');

var init = () => {
  projectSlideshows.map(i => i[0].classList.add('activeSlide'));
  indexSlide();
}

var indexSlide = () => {
  projectSlideshows.map(slideshow => {
    slideshow.forEach((slide, i, array) => {
      slide.dataset.index = i + 1;
      slide.dataset.total = array.length;
      if (i > 0) slide.classList.add('inactiveSlide');
    })
  })
  updateCount();
}

var slideChange = (el) => {
  let activeSlide = Array.from(document.querySelectorAll('.activeSlide'));
  activeSlide.map(active => {
    if (el != undefined && el.id == "slidePrev"){
      active.dataset.index * 1 > 1 ? prevSlide(active, active.previousElementSibling) : lastSlide(active);
    } else {
      active.dataset.index * 1 < active.dataset.total * 1 ? nextSlide(active, active.nextElementSibling) : firstSlide(active);
    }
  })
  updateCount();
}

var prevSlide = (el, prev) =>{
  el.classList.remove('activeSlide');
  el.classList.add('inactiveSlide');
  prev.classList.remove('inactiveSlide');
  prev.classList.add('activeSlide');
}

var nextSlide = (el, next) => {
  el.classList.remove('activeSlide');
  el.classList.add('inactiveSlide');
  next.classList.remove('inactiveSlide');
  next.classList.add('activeSlide');
}

var lastSlide = (el) => {
  el.classList.remove('activeSlide');
  projectSlideshows.map(slideshow => {
    slideshow[slideshow.length - 1].classList.add('activeSlide');
    slideshow.forEach((slide, i, array) => {
      slide.classList.remove('inactiveSlide');
      if (i < array.length - 1) slide.classList.add('inactiveSlide');
    })
  })
}

var firstSlide = (el) => {
  el.classList.remove('activeSlide');
  projectSlideshows.map(slideshow => {
    slideshow[0].classList.add('activeSlide');
    slideshow.forEach((slide, i, array) => {
      slide.classList.remove('inactiveSlide');
      if (i > 0) slide.classList.add('inactiveSlide');
    })
  })
}

var updateCount = () => {
  if(document.getElementById('indexSpan')) document.getElementById('indexSpan').outerHTML = '';
  if(document.getElementById('totalSpan')) document.getElementById('totalSpan').outerHTML = '';
  let activeSlide = document.querySelector('.activeSlide');
  let indexSpan = document.createElement('span');
  let totalSpan = document.createElement('span');
  indexSpan.id = 'indexSpan';
  totalSpan.id = 'totalSpan';
  indexSpan.innerHTML = activeSlide.dataset.index;
  totalSpan.innerHTML = activeSlide.dataset.total;
  projectSlideCount.parentNode.insertBefore(indexSpan, projectSlideCount);
  projectSlideCount.parentNode.insertBefore(totalSpan, projectSlideCount);
  history.pushState(null,null,'#' + activeSlide.dataset.project + '-' + activeSlide.dataset.num);
}

var goHash = (hash) => {
  hash = hash.split('#')[1];
  let projectHash = hash.substring(0, hash.lastIndexOf("-"));
  let indexHash = hash.substring(hash.lastIndexOf("-") + 1, hash.length) * 1;
  let slideHash = Array.from(document.querySelectorAll('[data-project="'+ projectHash +'"][data-num="'+ indexHash +'"]'));
  let activeSlide = Array.from(document.querySelectorAll('.activeSlide'));

  history.pushState(null,null,'#' + projectHash + '-' + indexHash);

  activeSlide.map(active => {
    active.classList.toggle('activeSlide');
    active.classList.toggle('inactiveSlide');
  })

  slideHash.map(slide => {
    slide.classList.toggle('activeSlide');
    slide.classList.toggle('inactiveSlide');
  })

  updateCount();
}

var zoom = (e, zoomDiv) => {
  var offsetY = e.pageY;
  var offsetX = e.pageX;
  var wHeight = window.innerHeight;
  var wWidth = window.innerWidth;
  var p = offsetY / wHeight * 100;
  var r = offsetX / wWidth * 100;
  var zHeight = zoomDiv.getBoundingClientRect().height - 10;
  var zWidth = zoomDiv.getBoundingClientRect().width;
  var y = zHeight - wHeight;
  var x = zWidth - wWidth;
  var z = p * y / 100;
  var w = r * x / 100;
  zoomDiv.style.top = -z + 'px';
  zoomDiv.style.left = -w + 'px';
}

export { init, slideChange, goHash, zoom };
